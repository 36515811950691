import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { FormLabel, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useRecoilState } from "recoil";
import { adminAuditFilterAtom } from "../../../atoms/atoms";

export default function AdminAuditorFilter({ data, setFilterDataset }) {
  const [auditors, setAuditors] = React.useState([]);
  const [historyFilters, setHistoryFilters] =
    useRecoilState(adminAuditFilterAtom);

  React.useEffect(() => {
    const uniqueAuditors = new Set(data.map((d) => d.auditor));
    setAuditors(Array.from(uniqueAuditors));
  }, [data]);

  function onChange(newValue) {
    if (newValue !== null) {
      setHistoryFilters((prev) => ({
        ...prev,
        auditor: newValue,
        filtered: true,
      }));
    } else {
      setHistoryFilters((prev) => ({
        ...prev,
        auditor: "",
      }));
    }
  }

  return (
    <Stack sx={{ width: "33%" }}>
      <Typography variant="overline">Filter by Auditor</Typography>
      <Autocomplete
        sx={{ background: "white" }}
        options={auditors}
        id="controlled-demo"
        value={historyFilters.auditor}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        renderInput={(params) => {
          return <TextField {...params} label="Auditor" />;
        }}
      />
    </Stack>
  );
}
