import * as React from "react";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export default function RestrictedToggle({
  restrictedToggle,
  setRestrictedToggle,
  setFilterOptions,
}) {
  const [selectItems, setSelectItems] = React.useState([
    "Restricted",
    "Non-Restricted",
    "Both",
  ]);

  const getRestrictedValue = (selection) => {
    switch (selection) {
      case "Restricted":
        return true;
      case "Non-Restricted":
        return false;
      case "Both":
        return null;
      default:
        return null;
    }
  };
  const handleFilter = (event) => {
    const selection = event.target.value;
    setRestrictedToggle([selection]);
    setFilterOptions((prev) => ({
      ...prev,
      is_esp_restricted: getRestrictedValue(selection),
    }));
  };

  return (
    <Box className="filterBar">
      <FormControl sx={{ m: 1, minWidth: 165 }}>
        <InputLabel id="demo-simple-select-autowidth-label">
          Restricted ESP
        </InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={restrictedToggle}
          onChange={handleFilter}
          autoWidth
          label="Restricted ESP"
          placeholder="Restricted ESP"
          sx={{ backgroundColor: "whitesmoke" }}
          style={{ width: 250 }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>

          {selectItems.map((item, index) => {
            return (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
