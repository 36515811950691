import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Stack,
  TextField,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import dayjs from "dayjs";
import React from "react";
import { EncounterService } from "../../services/encounterService";
import { UserContext } from "../../contexts/userContext";
import ClearIcon from "@mui/icons-material/Clear";
import Swal from "sweetalert2";
import { FacilityService } from "../../services/facilityService";
import { AuditService } from "../../services/auditService";
import LoadingBlock from "../LoadingBlock";
import { NonmatchedService } from "../../services/nonmatchedService";
//For some reason no longer populating with the new information from notes//
export default function EncounterForm({
  cardInfo,
  markAudited,
  handleClose,
  viewOnly,
}) {
  const [encounterDate, setEncounterDate] = React.useState("");
  const [locationSelect, setLocationSelect] = React.useState([]);
  const [locationPick, setLocationPick] = React.useState("Location");
  const [exists, setExists] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { user, saveUser, saveFormUser } = React.useContext(UserContext);
  const [submitButton, setSubmitButton] = React.useState(true);
  const [status, setStatus] = React.useState();
  const [notesArray, setNotesArray] = React.useState([]);
  const [readOnly, setReadOnly] = React.useState(false);
  const [formValues, setFormValues] = React.useState({
    encounterFound: false,
    encounterDate: encounterDate,
    visitId: "",
    MRN: "",
    outcome: "",
    notes: "",
    location: "",
  });
  const { checkInRecord } = NonmatchedService();
  const { userOptOut } = AuditService();
  const { getEncounter, submitEncounter, updateEncounter } = EncounterService();
  const { getAllFacilities } = FacilityService();
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
  });

  React.useEffect(() => {
    setReadOnly(cardInfo.isReaudit);
    saveFormUser();
    setLoading(true);

    setSubmitButton(true);
    getEncounter(
      cardInfo.rx_prx_id,
      (d) => {
        console.log(d);
        setExists(true);
        setFormValues({
          encounterFound: d.found,
          encounterDate: dayjs(d.encounterDate),
          visitId: d.visitId,
          MRN: d.mrn,
          outcome: d.outcome,
          // notes: d[0].notes,
          location: d.location,
        });
        if (d.facility_id != null) {
          setLocationPick(d.facility_id);
        }
        setEncounterDate(dayjs(d.encounterDate));
        setNotesArray(d.notes ? d.notes : []);

        setLoading(false);
      },
      (err) => {
        setExists(false);
        setLoading(false);
        console.log(err);
      }
    );
    getAllFacilities(
      (data) => {
        setLocationSelect(data);
        setLocationPick(0);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  React.useEffect(() => {
    if (formValues.outcome === "Remain Non Matched") {
      setSubmitButton(false);
    } else if (formValues.encounterDate) {
      if (formValues.visitId) {
        if (formValues.MRN) {
          if (
            formValues.outcome &&
            (formValues.outcome === "Reverse/Requalify" ||
              formValues.outcome === "Pending CE Review")
          ) {
            setSubmitButton(false);
          }
        }
      }
    }
  }, [formValues]);

  const handleChangeEncounterDate = (newValue) => {
    setEncounterDate(newValue);
    setFormValues((prev) => ({
      ...prev,
      encounterDate: newValue,
    }));
  };
  const handleSelectLocation = (e) => {
    setFormValues((prev) => ({
      ...prev,
      location: e.target.value,
    }));
    setLocationPick(e.target.value);
  };
  const handleSelectOutcome = (e) => {
    if (e.target.value == "Pending CE Review") {
      setStatus(() => 6);
    }
    setFormValues((prev) => ({
      ...prev,
      outcome: e.target.value,
    }));
  };

  const updateForm = (submit = false) => {
    updateEncounter(
      cardInfo.rx_prx_id,
      formValues.encounterFound,
      formValues.encounterDate ? formValues.encounterDate.$d : null,
      formValues.visitId,
      formValues.location,
      formValues.MRN,
      formValues.outcome,
      formValues.notes,
      status ? status : 2,
      submit,
      (data) => {
        setLoading(false);
        swalWithBootstrapButtons.fire(
          submit ? "Submitted" : "Saved!",
          `Encounter form for Facility: ${cardInfo.pk.facility_id}, Pharmacy: ${cardInfo.pk.pharmacy_id}, Rx Number: ${cardInfo.pk.rx_number}, Fill Number: ${cardInfo.pk.rx_fill_number} has been saved.`,
          "success"
        );
      },
      (err) => {
        console.log(err);
        setLoading(false);
        swalWithBootstrapButtons.fire(
          "Error.",
          `There was an error processing your request. ${err}`,
          "error"
        );
      }
    );
    markAudited();
  };
  const handleSave = () => {
    //handleClose();
    if (!user) return;
    if (user.opt_out_save) {
      saveFormUser();
      setLoading(true);
      updateForm(false);
    } else {
      Swal.fire({
        text: "Save this record?",
        showCancelButton: true,
        confirmButtonColor: "#1976d2",
        confirmButtonText: "Confirm",
        cancelButtonColor: "#769FCD",
        color: "black",
        footer: `
    <input type="checkbox" id="agree-checkbox" style="width: 10px; height: 10px;">
    <label for="agree-checkbox" style="font-size:12px;">Don't show this message again</label>
  `,
        preConfirm: () => {
          if (document.getElementById("agree-checkbox").checked) {
            userOptOut(
              true,
              "save",
              (data) => {},
              (err) => {
                console.log(err);
              }
            );
            const newUser = {
              ...user,
              opt_out_save: true,
            };
            saveUser(newUser);
            return true;
          } else {
            return undefined;
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          saveFormUser();
          setLoading(true);
          updateForm(false);
        }
      });
    }
  };

  const handleEditForm = () => {
    setReadOnly(false);
  };
  const handleSubmit = () => {
    //handleClose();
    if (!user) return;
    if (user.opt_out_submit) {
      saveFormUser();
      setLoading(true);
      updateForm(true);
    } else {
      Swal.fire({
        title: "Submit this record?",
        text: "Once submitted, the record can no longer be edited.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1976d2",
        confirmButtonText: "Confirm",
        cancelButtonColor: "#769FCD",
        color: "black",
        footer: `
    <input type="checkbox" id="agree-checkbox" style="width: 10px; height: 10px;">
    <label for="agree-checkbox" style="font-size:12px;">Don't show this message again</label>
  `,
        preConfirm: () => {
          if (document.getElementById("agree-checkbox").checked) {
            userOptOut(
              true,
              "submit",
              (data) => {},
              (err) => {
                console.log(err);
              }
            );
            const newUser = {
              ...user,
              opt_out_submit: true,
            };
            saveUser(newUser);
            return true;
          } else {
            return undefined;
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          saveFormUser();
          updateForm(true);
        }
      });
    }
  };

  //if (loading) return <LoadingBlock />;

  return loading ? (
    <CircularProgress />
  ) : (
    <Paper
      className="encounterForm"
      sx={{ backgroundColor: "#B9D7EA", padding: 1, width: "800px" }}
    >
      <Typography align="right">
        {/* <Button onClick={() => handleClose()}>
          <ClearIcon />
        </Button> */}
      </Typography>
      <Stack direction={"column"} flex>
        <Card sx={{ width: "100%" }}>
          <CardHeader
            title="Encounter Form"
            text="user"
            sx={{ textAlign: "center", backgroundColor: "whitesmoke" }}
          />
          <CardContent sx={{}}>
            <Typography
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ m: 0.5 }}>
                <u>
                  <b>RX ID:</b>
                </u>
                {` ${
                  cardInfo.scriptNumberDisplay
                    ? cardInfo.scriptNumberDisplay
                    : `${JSON.stringify(cardInfo.pk, undefined, " ")}`
                }`}
              </Typography>
              <Typography sx={{ m: 0.5 }}>
                <u>
                  <b>Prescribing Physician:</b>
                </u>
                {` ${cardInfo.prescriberName}`}
              </Typography>
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                m: 1,
              }}
            >
              <Typography sx={{ m: 0.5 }}>
                <u>
                  <b>Drug Name:</b>
                </u>
                {` ${cardInfo.drugName}`}
              </Typography>
              <Typography sx={{ m: 0.5 }}>
                <u>
                  {" "}
                  <b>Date Written:</b>
                </u>{" "}
                {`${cardInfo.dateWritten}`}
              </Typography>
            </Typography>
          </CardContent>
        </Card>
        <Stack direction={"row"} flex sx={{ width: "100%" }}>
          <Paper
            sx={{
              textAlign: "center",
              backgroundColor: "#D6E6F2",
              padding: 2,
              width: "50%",
              m: 1,
            }}
          >
            <Card sx={{ width: "90%", m: 1 }}>
              <FormControl
                disabled={readOnly}
                onChange={(e) =>
                  setFormValues((prev) => ({
                    ...prev,
                    encounterFound: e.target.value,
                  }))
                }
              >
                <Typography>Encounter Found? *</Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={formValues.encounterFound}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Card>
            <Card sx={{ width: "90%", m: 1 }}>
              <Typography sx={{ m: 1 }}>Encounter Date *</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Typography sx={{ m: 1 }}>
                  <DesktopDatePicker
                    disabled={readOnly}
                    label="Encounter Date"
                    inputFormat="MM/DD/YYYY"
                    value={encounterDate}
                    onChange={handleChangeEncounterDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Typography>
              </LocalizationProvider>
            </Card>
            <Card sx={{ width: "90%", m: 1 }}>
              <Typography sx={{ m: 1 }}>
                Visit ID (Encounter Number) *
              </Typography>
              <TextField
                required
                id="standard-required"
                value={formValues.visitId}
                variant="standard"
                sx={{ m: 1, width: "90%" }}
                disabled={readOnly}
                onChange={(e) =>
                  setFormValues((prev) => ({
                    ...prev,
                    visitId: e.target.value,
                  }))
                }
              />
            </Card>
            <Card sx={{ width: "90%", m: 1 }}>
              <Typography sx={{ m: 1 }}>Encounter Location *</Typography>

              <FormControl disabled={readOnly}>
                <Select
                  labelId="locations-select"
                  id="locations-selection"
                  value={locationPick}
                  onChange={handleSelectLocation}
                  autoWidth
                  label={locationPick}
                  sx={{ backgroundColor: "whitesmoke" }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {locationSelect.map((loc, index) => (
                    <MenuItem key={index} value={loc.id}>
                      {loc.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Card>
          </Paper>
          <Paper
            sx={{
              textAlign: "center",
              backgroundColor: "#D6E6F2",
              padding: 1,
              width: "50%",
              m: 1,
            }}
          >
            <Card sx={{ width: "90%", m: 1 }}>
              <Typography sx={{ m: 1 }}>MRN *</Typography>
              <TextField
                required
                disabled={readOnly}
                id="standard-required"
                label="Required"
                value={formValues.MRN}
                variant="standard"
                sx={{ m: 1, width: "90%" }}
                onChange={(e) =>
                  setFormValues((prev) => ({ ...prev, MRN: e.target.value }))
                }
              />
            </Card>
            <Card sx={{ width: "90%", m: 1 }}>
              <FormControl onChange={handleSelectOutcome} disabled={readOnly}>
                <Typography component="span">
                  <Typography
                    id="demo-controlled-radio-buttons-group"
                    variant="subtitle2"
                    sx={{ fontSize: 12 }}
                  ></Typography>
                  <Typography>Outcome *</Typography>
                </Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={formValues.outcome}
                >
                  <FormControlLabel
                    value="Reverse/Requalify"
                    control={<Radio />}
                    label="Reverse/Requalify"
                  />
                  <FormControlLabel
                    value="Remain Non Matched"
                    control={<Radio />}
                    label="Remain Non Matched"
                  />
                  <FormControlLabel
                    value="Pending"
                    control={<Radio />}
                    label="Pending"
                  />
                  <FormControlLabel
                    value="Pending CE Review"
                    control={<Radio />}
                    label="Pending CE Review"
                  />
                </RadioGroup>
              </FormControl>
            </Card>
            <Card sx={{ width: "90%", m: 1 }}>
              <Typography sx={{ m: 1 }}> Notes</Typography>
              <TextField
                disabled={readOnly}
                label="Notes"
                value={formValues.notes}
                variant="standard"
                sx={{ m: 1, width: "90%" }}
                onChange={(e) =>
                  setFormValues((prev) => ({ ...prev, notes: e.target.value }))
                }
              />
              {notesArray.map((n) =>
                n.note_type == "Unsubmit" ? (
                  <Typography>
                    <b>{`${dayjs(n.note_date_created).format(
                      "MMMM D, YYYY"
                    )} : ${n.note}`}</b>
                  </Typography>
                ) : (
                  <Typography>{`${dayjs(n.note_date_created).format(
                    "MMMM D, YYYY"
                  )} : ${n.note}`}</Typography>
                )
              )}
            </Card>
          </Paper>
        </Stack>
      </Stack>
      <Box
        m={1}
        display="flex"
        justifyContent={"flex-end"}
        alignItems="flex-end"
      >
        {!viewOnly && !readOnly && (
          <Stack direction={"row"}>
            <Button variant="contained" sx={{ m: 1 }} onClick={handleSave}>
              Save
            </Button>

            <Button
              variant="contained"
              sx={{ m: 1 }}
              onClick={handleSubmit}
              disabled={submitButton}
            >
              Submit
            </Button>
          </Stack>
        )}
        {readOnly && (
          <Stack direction={"row"}>
            <Button variant="contained" sx={{ m: 1 }} onClick={handleEditForm}>
              Edit
            </Button>
          </Stack>
        )}
      </Box>
    </Paper>
  );
}
