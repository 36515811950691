import axios from "axios";
import { jwtDecode } from "jwt-decode";

const urls = require("../config").default.urls;

const api = axios.create({
  baseURL: urls.base,
});

api.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "token"
)}`;

api.interceptors.request.use(
  async (config) => {
    // console.log("Request interceptor hit:", config.url);
    if (config.url === REFRESH_URL) {
      // console.log("INTERCEPT REFRESH URL");
      return config;
    }
    const token = await refreshToken();
    if (token) {
      // console.log("INTERCEPT NEW TOKEN REQUEST");
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    // console.log("Response headers:", response.headers);
    const newToken =
      response.headers["x-new-token"] || response.headers["X-New-Token"];
    if (newToken) {
      // console.log("Found new token");
      localStorage.setItem("token", newToken);
      // Add this line to verify the token was saved
      // console.log(
      //   "Token in localStorage after save:",
      //   localStorage.getItem("token")
      // );
      api.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const tenantValidate = async (prefix, onSuccess, onError) => {
  await axios
    .post(
      `${urls.base}/tenant/validate`,

      { prefix }
    )
    .then(({ data }) => {
      //     localStorage.setItem('token', data.token);

      onSuccess(data);
    })
    .catch(({ response }) => {
      console.log(response);
      onError && onError(response);
    });
};

export const getUserInfo = async (onSuccess, onError) => {
  try {
    const token = localStorage.getItem("token");
    // console.log("Token retrieved for getUserInfo:", token); // Debug log

    const { data } = await api.get(`/Login/loggedInUser`);
    //console.log(data);
    onSuccess(data);
  } catch (error) {
    console.error("getUserInfo error:", error.response); // Debug log
    onError && onError(error.response);
  }
};

export const changePassword = async (
  currentPassword,
  newPassword,
  onSuccess,
  onError
) => {
  try {
    const { data } = await api.post(`/Login/ResetPassword`, {
      currentPassword,
      password: newPassword,
    });
    onSuccess(data);
  } catch (error) {
    console.log(error);
    onError && onError(error);
  }
};

export const login = async (
  prefix,
  userName,
  password,
  tenant,
  onSuccess,
  onError
) => {
  try {
    const { data } = await api.post(`/login`, { userName, password, tenant });
    localStorage.setItem("token", data.token);
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    // Check if password reset is required
    if (data.changePassword) {
      onSuccess({ changePassword: true });
    } else {
      onSuccess(data);
    }
  } catch (error) {
    console.log(error.response);
    onError && onError(error.response);
  }
};

//Too many token requests, going to limit it to one request per 5 mins
let lastRefreshTime = 0;
const refreshInterval = 300; //5mins
const REFRESH_URL = "/Login/refresh-token";

export const refreshToken = async () => {
  const currentToken = localStorage.getItem("token");
  if (currentToken) {
    const decodedToken = jwtDecode(currentToken);
    //    console.log(decodedToken);
    const currentTime = Date.now() / 1000;

    // If token is about to expire in the next 5 minutes, refresh it
    if (decodedToken.exp - currentTime < refreshInterval) {
      //  console.log("5 min warning");
      if (currentTime - lastRefreshTime >= refreshInterval) {
        //  console.log("getting new token");
        try {
          const response = await api.post(REFRESH_URL);
          const newToken = response.data.token;
          localStorage.setItem("token", newToken);
          api.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
          lastRefreshTime = currentTime; //dont really need but just in case
          //  let t = jwtDecode(localStorage.getItem("token"));
          // console.log(new Date(t.exp * 1000).toLocaleString());
          return newToken;
        } catch (error) {
          console.warn("Error refreshing token:", error);
          //logout here?
          return null;
        }
      }
    }
  }
  return currentToken;
};

export { api };
