//Main screen
import * as React from "react";
import FilterBar from "../components/encounter/EncounterFilterBar";
import PrescriberCards from "../components/encounter/EncounterPrescriptionInfoCards";
import { Stack } from "@mui/system";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { NonmatchedService } from "../services/nonmatchedService";
import { UserContext } from "../contexts/userContext";
import { useRecoilState } from "recoil";
import { auditFilterAtom, cardInfoAtom, pageAtom } from "../atoms/atoms";
import { prescriptionGen } from "../utils/dictGen";
import { Box, CircularProgress } from "@mui/material";
import ErrorPage from "./ErrorPage";

export default function Encounter() {
  const [filterOptions, setFilterOptions] = useRecoilState(auditFilterAtom);
  const [cardInfo, setCardInfo] = useRecoilState(cardInfoAtom);
  const [pages, setPages] = useRecoilState(pageAtom);
  const [loading, setLoading] = React.useState(false);
  const userCtx = React.useContext(UserContext);
  const PER_PAGE = 15;
  const theme = useTheme();
  const { checkInAllRecords, getAllNonmatched, getNumberOfPages } =
    NonmatchedService();
  const userPermissions = userCtx.user.user_secondary_roles.filter(
    (r) => r == 4
  );

  //dont need because count is returned in linqQ
  // async function getPages() {
  //   getNumberOfPages(
  //     filterOptions,
  //     (data) => {
  //       console.log(data);
  //       const total = data.count;
  //       const pageCount = Math.ceil(parseInt(total) / PER_PAGE);
  //       setPages((prev) => ({
  //         ...prev,
  //         numberOfPages: pageCount,
  //       }));
  //     },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }
  async function getSubset() {
    setLoading(true);
    getAllNonmatched(
      PER_PAGE,
      pages.page,
      filterOptions,
      (data) => {
        const allInfo = prescriptionGen(data.all);
        setCardInfo(allInfo);
        const totalPages = Math.ceil(data.count / PER_PAGE);
        setPages((prev) => ({
          ...prev,
          numberOfPages: totalPages,
        }));
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }

  const checkIn = () => {
    checkInAllRecords(
      userCtx.user.user_id,
      (data) => {},
      (err) => {
        console.log(err);
      }
    );
  };

  React.useEffect(() => {
    checkIn();
    const filtered = filterOptions.filtered;
    if (filtered) return;
    // getPages();
    getSubset();
  }, [setCardInfo, pages.page]);

  if (userPermissions.length < 1) {
    return <ErrorPage />;
  }

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <ThemeProvider theme={theme}>
      <Stack direction={"column"} spacing={2} position={"relative"}>
        <FilterBar reset={getSubset} checkIn={checkIn} />
        <PrescriberCards getSubset={getSubset} />
      </Stack>
    </ThemeProvider>
  );
}
