import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router";
import { UserContext } from "../contexts/userContext";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import { login, getUserInfo, changePassword } from "../services/loginService";
import cookies from "js-cookie";
import Swal from "sweetalert2";
import Modal from "@mui/material/Modal";
import ChangePasswordForm from "../components/changePasswordForm";
const theme = createTheme();

export default function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const userCtx = React.useContext(UserContext);
  const [changePasswordOpen, setChangePasswordOpen] = React.useState(false);
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
  });
  const prefix = cookies.get("tenant");

  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        login(
          prefix,
          values.userName,
          values.password,
          cookies.get("tenant"),
          (data) => {
            //   console.log(data);
            if (data.changePassword) {
              setChangePasswordOpen(true);
            } else {
              getUserInfo(
                (data1) => {
                  userCtx.saveUser(data1);
                },
                (err1) => {
                  console.log(err1);
                  swalWithBootstrapButtons.fire(
                    "Error",
                    "Failed to get user info",
                    "error"
                  );
                }
              );
              navigate(`/`);
            }
          },
          (err) => {
            console.log(err);
            swalWithBootstrapButtons.fire(
              "Error",
              `Invalid Login Attempt.`,
              "error"
            );
          }
        );
      } catch (ex) {
        console.log(ex);
        swalWithBootstrapButtons.fire(
          "Error",
          "An unexpected error occurred",
          "error"
        );
      }
      setLoading(false);
    },
  });

  const handlePasswordChange = async (values) => {
    setLoading(true);
    try {
      await changePassword(
        values.currentPassword,
        values.newPassword,
        () => {
          setChangePasswordOpen(false);
          swalWithBootstrapButtons.fire(
            "Success",
            "Password changed successfully. Please log in with your new password.",
            "success"
          );
          formik.setFieldValue("password", "");
        },
        (error) => {
          console.log(error);
          swalWithBootstrapButtons.fire(
            "Error",
            "Failed to change password. Please try again.",
            "error"
          );
        }
      );
    } catch (ex) {
      console.log(ex);
      swalWithBootstrapButtons.fire(
        "Error",
        "An unexpected error occurred while changing password",
        "error"
      );
    }
    setLoading(false);
  };

  const resetTenant = () => {
    cookies.remove("tenant");
    navigate("/login");
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src="https://www.proxsysrx.com/wp-content/uploads/2021/09/ProxsysRx-Logo.png"
            className="logo"
            alt="Logo"
            width={"50%"}
            height={"50%"}
          />
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              id="userName"
              name="userName"
              label="Username"
              placeholder="Username"
              margin="normal"
              fullWidth
              autoFocus
              value={formik.values.userName}
              onChange={formik.handleChange}
              error={formik.touched.userName && Boolean(formik.errors.userName)}
              helperText={formik.touched.userName && formik.errors.userName}
            />
            <TextField
              id="password"
              name="password"
              type="password"
              label="Password"
              placeholder="Something Strong"
              margin="normal"
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <LoadingButton
              type="submit"
              fullWidth
              loading={loading}
              variant="contained"
              sx={{ mt: 3, mb: 2, background: "#15157E" }}
            >
              Sign In
            </LoadingButton>
            <Grid container>
              <Grid item xs>
                <Link href="mailto:support@proxsysrx.com" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/" onClick={resetTenant} variant="body2">
                  Change Tenant
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Typography
            p={2}
            variant="h6"
          >{`Current Tenant: ${prefix.toUpperCase()}`}</Typography>
        </Box>
      </Container>
      <Modal
        open={changePasswordOpen}
        onClose={() => setChangePasswordOpen(false)}
        aria-labelledby="change-password-modal"
        aria-describedby="modal-to-change-password"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Change Password
          </Typography>
          <ChangePasswordForm
            onSubmit={handlePasswordChange}
            onCancel={() => setChangePasswordOpen(false)}
          />
        </Box>
      </Modal>
    </ThemeProvider>
  );
}
