import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { UserContext } from "../../contexts/userContext";
import { AdminService } from "../../services/adminService";
import Swal from "sweetalert2";
import AdminDateRangePicker from "./AdminDateRangePicker";
import fileDownload from "js-file-download";

export default function AdminExportComplianceReport({ setAnchorEl }) {
  const [fromDate, setFromDate] = React.useState();
  const [toDate, setToDate] = React.useState();
  const { getComplianceExport } = AdminService();
  const userCTX = React.useContext(UserContext);
  const { user } = userCTX;
  const [loading, setLoading] = React.useState(false);

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
  });

  const handleSubmit = () => {
    setLoading(true);
    if (!fromDate || !toDate) {
      swalWithBootstrapButtons.fire(
        "Error.",
        "Must have a date range selected",
        "error"
      );
    }
    getComplianceExport(
      fromDate,
      toDate,
      (data, headers) => {
        const contentDisposition = headers["content-disposition"];
        let filename = "download.xlsx"; // Default filename if not found
        if (contentDisposition) {
          const matches = contentDisposition.match(/filename="([^"]+)"/);
          if (matches && matches.length > 1) {
            filename = matches[1];
          }
        }

        fileDownload(data, filename);
        setLoading(false);
      },
      (err) => {
        setAnchorEl(null);
        swalWithBootstrapButtons.fire(
          "Error.",
          `There was an error processing your request or no records matched your query.`,
          "error"
        );
        setLoading(false);
      }
    );
  };
  if (!user || user.role_id != 3) return;

  return (
    <Paper
      variant="outlined"
      sx={{
        minWidth: 400,
        position: "relative",
        //display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h5" textAlign={"center"} m={2}>
        Compliance Export
      </Typography>
      <Typography
        component={"span"}
        sx={{ display: "flex", flexDirection: "column", m: 2 }}
      >
        <AdminDateRangePicker setFromDate={setFromDate} setToDate={setToDate} />
      </Typography>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50px",
            m: 2,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{ width: "100%", marginBottom: 2 }}
        >
          Download Export
        </Button>
      )}
    </Paper>
  );
}
