import React from "react";
import { DateRangePicker, DateRange } from "@mui/x-date-pickers-pro";
import { TextField, Box, Stack, Button, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import { adminAuditFilterAtom, historyFilterAtom } from "../../atoms/atoms";

const AuditDateRangePicker = ({
  original,
  data,
  setFilterDataset,
  type,
  dateRange,
  setDateRange,
}) => {
  const [historyFilters, setHistoryFilters] = useRecoilState(historyFilterAtom);
  const [adminAudit, setAdminAudit] = useRecoilState(adminAuditFilterAtom);

  const handleDateChange = (date) => {
    setDateRange(date);
    let fromDate = new Date(date[0].$d);
    fromDate.setHours(0, 0, 0, 0);
    fromDate = fromDate.toISOString();
    let toDate = new Date(date[1].$d);
    toDate.setHours(23, 59, 59, 999);
    toDate = toDate.toISOString();
    if (type == "admin") {
      setAdminAudit((prev) => ({
        ...prev,
        start_date: fromDate,
        end_date: toDate,
        filtered: true,
      }));
    } else {
      setHistoryFilters((prev) => ({
        ...prev,
        start_date: fromDate,
        end_date: toDate,
        filtered: true,
      }));
    }
    let newData = data.filter(
      (d) =>
        new Date(d.auditDateCreated) >= new Date(fromDate) &&
        new Date(d.auditDateCreated) <= new Date(toDate)
    );
    setFilterDataset(newData);
  };
  const handleReset = () => {
    setDateRange([null, null]);
    if (type == "admin") {
      setAdminAudit((prev) => ({
        ...prev,
        auditor: "",
        pharmacy: "",
        auditType: "",
        outcome: "",
        start_date: null,
        end_date: null,
        filtered: false,
      }));
    } else {
      setHistoryFilters((prev) => ({
        ...prev,
        auditor: "",
        rx_id: "",
        auditType: "",
        outcome: "",
        start_date: null,
        end_date: null,
        filtered: false,
      }));
    }
    setFilterDataset(original);
  };
  return (
    <Stack sx={{ m: 1, display: "flex" }}>
      <Typography variant="overline">Filter by Audit Date</Typography>
      <Stack sx={{ m: 1, display: "flex" }} direction={"row"}>
        <DateRangePicker
          startText="Start Date"
          endText="End Date"
          value={dateRange}
          disabled={false}
          onChange={(newValue) => handleDateChange(newValue)}
          renderInput={(startProps, endProps) => {
            return (
              <React.Fragment>
                <TextField
                  {...startProps}
                  sx={{ background: "whitesmoke" }}
                  variant="outlined"
                />
                <Box sx={{ mx: 1 }}> to </Box>
                <TextField
                  {...endProps}
                  sx={{ background: "whitesmoke" }}
                  variant="outlined"
                />
              </React.Fragment>
            );
          }}
        />
      </Stack>
      {type == "admin" && (
        <Button sx={{ m: 1 }} variant="contained" onClick={handleReset}>
          {" "}
          Reset All{" "}
        </Button>
      )}
    </Stack>
  );
};

export default AuditDateRangePicker;
