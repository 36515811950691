import { atom, selector, waitForAll } from "recoil";
import { recoilPersist } from "recoil-persist";
import dayjs from "dayjs";

const { persistAtom } = recoilPersist({
  storage: sessionStorage,
});
const currentDate = dayjs();
const oneMonthAgo = currentDate.subtract(1, "month");
export const cardInfoAtom = atom({
  key: "cardInfo",
  default: [],
});
export const referralInfoAtom = atom({
  key: "referralInfo",
  default: [],
});
export const complianceInfoAtom = atom({
  key: "complianceInfo",
  default: [],
});
export const auditFilterAtom = atom({
  key: "auditFilters",
  default: {
    prescriber_name: "",
    status_id: "",
    rx_id: "",
    visit_id: "",
    start_date: "",
    end_date: "",
    is_esp_restricted: "",
    exclusive_prescriber: "",
    pharmacy_names: [],
    expanded: false,
    filtered: false,
  },
  effects_UNSTABLE: [persistAtom],
});
export const referralFilterAtom = atom({
  key: "referralFilters",
  default: {
    prescriber_name: "",
    status_id: "",
    rx_id: "",
    visit_id: "",
    start_date: "",
    end_date: "",
    is_esp_restricted: "",
    exclusive_prescriber: "",
    pharmacy_names: [],
    expanded: false,
    filtered: false,
  },
  effects_UNSTABLE: [persistAtom],
});
export const pageAtom = atom({
  key: "pages",
  default: {
    page: 1,
    numberOfPages: 1,
    filteredPage: 1,
    filteredNumberOfPages: 1,
    compliancePage: 1,
    complianceNumberOfPages: 1,
    complianceFilteredPage: 1,
    complianceFilteredNumberOfPages: 1,
    activePage: "",
    referralPage: 1,
    referralNumberOfPages: 1,
    referralFilteredPage: 1,
    referralFilteredNumberOfPages: 1,
  },
  effects_UNSTABLE: [persistAtom],
});
export const historyFilterAtom = atom({
  key: "historyFilters",
  default: {
    auditor: "",
    rx_id: "",
    auditType: "",
    outcome: "",
    start_date: null,
    end_date: null,
    filtered: false,
  },
  effects_UNSTABLE: [persistAtom],
});
export const complianceFilterAtom = atom({
  key: "complianceFilters",
  default: {
    prescriber_name: "",
    status_id: "",
    rx_id: "",
    visit_id: "",
    start_date: oneMonthAgo,
    end_date: currentDate,
    is_esp_restricted: "",
    exclusive_prescriber: "",
    pharmacy_names: [],
    expanded: false,
    filtered: false,
  },
  effects_UNSTABLE: [persistAtom],
});
export const staticDataAtom = atom({
  key: "staticData",
  default: {
    prescribers: [],
    pharmacies: [],
    compliancePrescribers: [],
    compliancePharmacies: [],
    referralPrescribers: [],
    referralPharmacies: [],
  },
});
export const adminAuditFilterAtom = atom({
  key: "adminAuditFilters",
  default: {
    auditor: "",
    pharmacy: "",
    auditType: "",
    start_date: null,
    end_date: null,
    filtered: false,
  },
  effects_UNSTABLE: [persistAtom],
});
export const allDataLoadedSelector = selector({
  key: "allDataLoadedSelector",
  get: ({ get }) => {
    const [cards, compliance, referrals] = get(
      waitForAll([cardInfoAtom, complianceInfoAtom, referralInfoAtom])
    );
    return cards.length > 0 || compliance.length > 0 || referrals.length > 0;
  },
});
