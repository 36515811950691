import { baseApi } from "./baseService";
import { LogOut } from "../utils/logOut";

export function NonmatchedService() {
  const API = {
    getAllNonmatched: "/nonmatched/all",
    getSubsetNonmatched: "/nonmatched/subset",
    markNonmatchedAsAudited: "/nonmatched/audited",
    getNumberOfPages: "/nonmatched/count",
    checkOutRecord: "/nonmatched/checkOut",
    checkInRecord: "/nonmatched/checkIn",
    checkInAllRecords: "/nonmatched/checkInAll",
    getByVisitId: "/nonmatched/visit",
    getFiltered: "/nonmatched/filter",
    getAllPrescribers: "/nonmatched/prescribers",
    getAllScripts: "/nonmatched/scripts",
    checkInRecordAdmin: "/nonmatched/checkInAdmin",
    getAllPharmacies: "/nonmatched/pharmacies",
  };
  const { loggedOut } = LogOut();
  const getAllNonmatched = (limit, offset, filters, onSuccess, onError) => {
    const payload = {
      limit,
      offset,
      ...filters,
    };

    baseApi().then((api) =>
      api
        .post(API.getAllNonmatched, payload)
        .then(({ data }) => {
          //console.log(data);
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          // loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const getSubsetNonmatched = (subset, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getSubsetNonmatched, { subset: subset })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const markNonmatchedAsAudited = async (
    nonmatchId,
    rx_prx_id,
    onSuccess,
    onError
  ) => {
    baseApi().then((api) =>
      api
        .post(API.markNonmatchedAsAudited, { nonmatchId, rx_prx_id })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const getNumberOfPages = (filters, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getNumberOfPages, filters)
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          // loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const checkOutRecord = (rx_prx_id, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.checkOutRecord, {
          rx_prx_id,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const checkInRecord = (rx_prx_id, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.checkInRecord, {
          rx_prx_id,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const checkInRecordAdmin = (audit_record_id, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.checkInRecordAdmin, {
          audit_record_id,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };
  const checkInAllRecords = (user_id, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.checkInAllRecords, {
          user_id,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          //loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const getByVisitId = (visit_id, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getByVisitId, { visit_id })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };
  const getFiltered = (filterOptions, limit, offset, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getFiltered, { filterOptions, limit, offset })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const getAllPrescribers = (onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getAllPrescribers)
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };
  const getAllPharmacies = (onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getAllPharmacies)
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };
  const getAllScripts = (onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getAllScripts)
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };
  return {
    getAllNonmatched,
    getSubsetNonmatched,
    markNonmatchedAsAudited,
    getNumberOfPages,
    checkOutRecord,
    checkInRecord,
    checkInAllRecords,
    getByVisitId,
    getFiltered,
    getAllPrescribers,
    getAllScripts,
    checkInRecordAdmin,
    getAllPharmacies,
  };
}
