import React from "react";
import { UserContext } from "../contexts/userContext";
import Swal from "sweetalert2";
//import { useNavigate } from "react-router-dom";

export function LogOut() {
  //const navigate = useNavigate();
  const userCtx = React.useContext(UserContext);
  const loggedOut = (err) => {
    if (!err) return;
    if (err.status == 401 && err.data.message == "You have been logged out.") {
      Swal.fire({
        title: "Logged Out",
        text: "You have been logged out due to inactivity.",
        icon: "warning",
        confirmButtonText: "OK",
      }).then(() => {
        userCtx.logout();
        window.location.href = "/Login";
      });
    } else if (err == "Disagree") {
      userCtx.logout();
      window.location.href = "/Login";
    }
  };
  return { loggedOut };
}
