//Compliance screen
import * as React from "react";
import { Stack } from "@mui/system";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { UserContext } from "../contexts/userContext";
import { useRecoilState } from "recoil";
import {
  complianceFilterAtom,
  complianceInfoAtom,
  pageAtom,
} from "../atoms/atoms";
import { useNavigate } from "react-router-dom";
import { prescriptionGen } from "../utils/dictGen";
import { ComplianceService } from "../services/complianceService";
import ComplianceCards from "../components/compliance/ComplianceInfoCards";
import { Box, CircularProgress, Paper } from "@mui/material";
import ComplianceFilterBar from "../components/compliance/ComplianceFilterBar";
import ErrorPage from "./ErrorPage";

export default function Compliance() {
  const [filterOptions, setFilterOptions] =
    useRecoilState(complianceFilterAtom);
  const [cardInfo, setCardInfo] = useRecoilState(complianceInfoAtom);
  const [pages, setPages] = useRecoilState(pageAtom);
  const [loading, setLoading] = React.useState(false);
  const userCtx = React.useContext(UserContext);
  const PER_PAGE = 15;
  const theme = useTheme();
  const navigate = useNavigate();
  const underConstruction = false;
  const userPermissions = userCtx.user.user_secondary_roles.filter(
    (r) => r == 6
  );
  const {
    getAllCompliance,
    getNumberOfCompliancePages,
    checkInAllComplianceRecords,
  } = ComplianceService();

  // async function getCompliancePages() {
  //   getNumberOfCompliancePages(
  //     (data) => {
  //       const total = data.count;
  //       const pageCount = Math.floor(parseInt(total) / PER_PAGE);
  //       setPages((prev) => ({
  //         ...prev,
  //         complianceNumberOfPages: pageCount,
  //       }));
  //     },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }

  async function getComplianceSubset() {
    setLoading(true);
    getAllCompliance(
      PER_PAGE,
      pages.compliancePage,
      filterOptions,
      (data) => {
        const allInfo = prescriptionGen(data.all);
        setCardInfo(allInfo);
        setLoading(false);
        const total = data.count;
        const pageCount = Math.ceil(parseInt(total) / PER_PAGE);
        setLoading(false);
        setPages((prev) => ({
          ...prev,
          complianceNumberOfPages: pageCount,
        }));
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }
  const complianceCheckIn = () => {
    checkInAllComplianceRecords(
      userCtx.user.user_id,
      (data) => {},
      (err) => {
        console.log(err);
      }
    );
  };
  React.useEffect(() => {
    complianceCheckIn();
    const filtered = filterOptions.filtered;
    if (filtered) return;
    //getCompliancePages();
    getComplianceSubset();
  }, [setCardInfo, pages.compliancePage]);

  if (userPermissions.length < 1) {
    return <ErrorPage />;
  }
  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  if (underConstruction) return <Paper>PAGE UNDER CONSTRUCTION</Paper>;
  return (
    <ThemeProvider theme={theme}>
      <Stack direction={"column"} spacing={2}>
        <ComplianceFilterBar
          reset={getComplianceSubset}
          complianceCheckIn={complianceCheckIn}
        />
        <ComplianceCards getComplianceSubset={getComplianceSubset} />
      </Stack>
    </ThemeProvider>
  );
}
