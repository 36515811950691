import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  Card,
  CardContent,
  Divider,
  CardHeader,
  CircularProgress,
  Button,
} from "@mui/material";
import { Stack } from "@mui/system";
import { AuditService } from "../../../services/auditService";
import dayjs from "dayjs";
import AdminUnsubmitPopover from "./AdminUnsubmitPopover";

export default function AuditHistoryDetailCard({
  row,
  allInfo,
  setAllInfo,
  setOpen,
}) {
  const [auditHistory, setAuditHistory] = React.useState([]);
  const [historyLoading, setHistoryLoading] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState(false);
  const [formInfo, setFormInfo] = React.useState({});
  const [notes, setNotes] = React.useState([]);
  const { getAuditDetail, getFormDetail } = AuditService();
  
  React.useEffect(() => {
    setHistoryLoading(true);
    setFormLoading(true);
    getAuditDetail(
      row.rx_prx_id,
      (data) => {
        setAuditHistory(data);
        setHistoryLoading(false);
      },
      (err) => {
        console.log(err);
        setHistoryLoading(false);
      }
    );
    getFormDetail(
      row.auditType,
      row.rx_prx_id,
      (data) => {
        
        
        setFormInfo(data.form);
        setNotes(data.notes);
        setFormLoading(false);
      },
      (err) => {
        console.log(err);
        setFormLoading(false);
      }
    );
  }, []);

  return (
    <Stack>
      <Stack direction={"row"} sx={{ width: "100%" }} flexWrap={"wrap"}>
        <Card
          key={row.pk}
          variant="outlined"
          className="prescriptionCard"
          sx={{
            backgroundColor: "#F7FBFC",
            boxShadow: 5,
            flexGrow: 1,
            maxWidth: "33.333%",
          }}
        >
          <CardHeader title="Prescription Record:" />
          <Stack direction={"column"}>
            <>
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography sx={{ display: "flex" }}>
                  <Typography sx={{ width: "100%" }} fontFamily={"unset"}>
                    {`Rx: ${row.pk.rx_number} : ${row.fills} Fill(s)`}
                  </Typography>
                </Typography>
                <Typography align="left" color="black" fontFamily={"unset"}>
                  {`Pharmacy: ${row.pharmacy}`}
                </Typography>
                <Typography align="left" color="black" fontFamily={"unset"}>
                  {`Patient Name: ${row.patientName}`}
                </Typography>
                <Typography align="left" color="black" fontFamily={"unset"}>
                  {`DOB: ${row.dob}`}
                </Typography>
                <Typography align="left" color="black" fontFamily={"unset"}>
                  {`Prescriber Name: ${row.prescriberName}`}
                </Typography>
                <Divider orientation="horizontal" flexItem sx={{ pb: 2 }} />
              </CardContent>
            </>
            <Divider orientation="vertical" flexItem />
            <Stack direction={"row"}>
              <CardContent>
                <Typography
                  align="center"
                  color="#282c34"
                  fontFamily={"unset"}
                  variant={"body1"}
                >
                  Date Written
                </Typography>
                <Divider flexItem />
                <Typography align="center" color="#58a5d3" pb={2}>
                  {row.dateWritten}
                </Typography>
                <Typography
                  align="center"
                  color="#282c34"
                  fontFamily={"unset"}
                  variant={"body1"}
                >
                  Date Dispensed
                </Typography>
                <Divider flexItem></Divider>
                <Typography align="center" color="#58a5d3">
                  {row.dateDispensed}
                </Typography>
                <Typography
                  align="center"
                  color="#282c34"
                  fontFamily={"unset"}
                  variant={"body1"}
                >
                  Nonmatch Reason
                </Typography>
                <Divider flexItem></Divider>
                <Typography align="center" color="#58a5d3">
                  {row.nonmatchReason}
                </Typography>
              </CardContent>
              <Divider orientation="vertical" flexItem />
              <CardContent>
                <Typography
                  align="center"
                  color="#282c34"
                  fontFamily={"unset"}
                  variant={"body1"}
                >
                  Primary Payor
                </Typography>
                <Divider flexItem />
                <Typography align="center" color="#58a5d3" pb={2}>
                  {row.primaryPayor}
                </Typography>
                <Typography
                  align="center"
                  color="#282c34"
                  fontFamily={"unset"}
                  variant={"body1"}
                >
                  Secondary Payor
                </Typography>
                <Divider flexItem />
                <Typography align="center" color="#58a5d3">
                  {row.secondaryPayor}
                </Typography>
              </CardContent>
              <Divider orientation="vertical" flexItem />
              <CardContent>
                <Typography
                  align="center"
                  color="#282c34"
                  fontFamily={"unset"}
                  variant={"body1"}
                >
                  Drug Name
                </Typography>
                <Divider flexItem />
                <Typography align="center" color="#58a5d3" pb={2}>
                  {row.drugName} {row.drugStrength}
                </Typography>
                <Typography
                  align="center"
                  color="#282c34"
                  fontFamily={"unset"}
                  variant={"body1"}
                >
                  NDC Number
                </Typography>
                <Divider flexItem />
                <Typography align="center" color="#58a5d3" pb={2}>
                  {row.ndc}
                </Typography>
                <Typography
                  align="center"
                  color="#282c34"
                  fontFamily={"unset"}
                  variant={"body1"}
                >
                  Potential 340B Savings
                </Typography>
                <Divider flexItem />
                <Typography align="center" color="#58a5d3" pb={2}>
                  {row.potentialSavings}
                </Typography>
              </CardContent>
            </Stack>
          </Stack>
        </Card>
        <Card
          sx={{
            backgroundColor: "#F7FBFC",
            boxShadow: 5,
            flexGrow: 2,
            maxWidth: "33.333%",
          }}
        >
          {row.status != 5 && (
            <Box>
              <Typography textAlign={"center"} variant="h6">
                Form Submitted:
              </Typography>
              <Divider />

              <Box sx={{ margin: 1 }} alignSelf="center">
                {row.auditType == "Referral" ? (
                  // <ReferralFormPopover cardInfo={row} viewOnly={true} />
                  <Typography textAlign={"center"}>Referral</Typography>
                ) : row.auditType == "Encounter" ? (
                  // <EncounterFormPopover cardInfo={row} viewOnly={true} />
                  <Typography textAlign={"center"}>Encounter</Typography>
                ) : row.auditType == "Compliance" ? (
                  <CardContent>
                    <Stack alignItems={"center"}>
                      <Typography variant="caption">
                        Compliance Qualified Correctly?
                      </Typography>
                      <Typography justifySelf={"center"} variant="overline">
                      {row.complianceQual.toString().toUpperCase()}
                      </Typography>
                    </Stack>
                    <Divider />
                    <Stack>
                      <Typography variant="caption">Notes:</Typography>
                      <Typography variant="overline">
                        {row.complianceNote}
                      </Typography>
                    </Stack>
                  </CardContent>
                ) : (
                  <Typography>No Form Submitted</Typography>
                )}
              </Box>

              <Typography textAlign={"center"} paddingTop={2}>
                Date Form Submitted:
              </Typography>
              <Divider />
              <Typography textAlign={"center"} variant="body1">
                {new Date(row.auditDateUpdated).toDateString()}
              </Typography>
            </Box>
          )}
          <CardContent>
            {formLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Typography variant="h6">Form Info:</Typography>
                <Divider />
                {notes.length > 0 && (
                  <>
                    <Typography>Notes History</Typography>
                    {notes.map((n, index) => (
                      <Typography
                        key={index}
                        variant="overline"
                        sx={{
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                          maxWidth: "100%",
                          display: "block",
                        }}
                      >{`${dayjs(n.note_date_created).format(
                        "MMMM D, YYYY"
                      )} : ${n.notes}`}</Typography>
                    ))}
                  </>
                )}
                {Object.entries(formInfo).map(([key, value]) => {
                  if (typeof value === "object") {
                    if (key === "notesJson") {
                      return (
                        <>
                          <Typography>
                            <b>Notes History</b>
                          </Typography>
                          {value.map((n, index) => (
                            <Typography
                              key={index}
                              variant="overline"
                              sx={{
                                wordWrap: "break-word",
                                overflowWrap: "break-word",
                                maxWidth: "100%",
                                display: "block",
                              }}
                            >{`${dayjs(n.note_date_created).format(
                              "MMMM D, YYYY"
                            )} : ${n.notes}`}</Typography>
                          ))}
                        </>
                      );
                    } else {
                      return;
                    }
                  } else {
                    return (
                      <Stack>
                        <Typography variant="body1">
                          <b>{key}</b>
                        </Typography>
                        <Typography
                          variant="overline"
                          sx={{
                            wordWrap: "break-word",
                            overflowWrap: "break-word",
                            maxWidth: "33%",
                          }}
                        >
                          {value}
                        </Typography>
                        <Divider />
                      </Stack>
                    );
                  }
                })}
              </>
            )}
          </CardContent>
        </Card>

        <Card
          sx={{
            alignContent: "centered",
            alignItems: "centered",
            backgroundColor: "#F7FBFC",
            boxShadow: 5,
            flexGrow: 2,
            //maxWidth: "33.333%",
          }}
        >
          <Typography sx={{ m: 1 }} variant="h6" gutterBottom component="div">
            Audit History
          </Typography>
          {historyLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Table sx={{ m: 1 }} size="small" aria-label="history">
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Auditor
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Check-Out
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Check-In
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Total Time (minutes)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {auditHistory.map((a, index) => (
                  <TableRow key={index}>
                    <TableCell>{`${a.user_last_name}, ${a.user_first_name}`}</TableCell>
                    <TableCell align="right">{dayjs(a.a.checkOutTime).format("MMMM D, YYYY  hh:mm:ss a")}</TableCell>
                    <TableCell align="right">{dayjs(a.a.checkInTime).format("MMMM D, YYYY  hh:mm:ss a")}</TableCell>
                    <TableCell align="right">
                      {Math.ceil(
                        Math.abs(
                          new Date(a.a.checkInTime) - new Date(a.a.checkOutTime)
                        ) / 60000
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Card>
      </Stack>
      <AdminUnsubmitPopover
        rx_prx_id={row.rx_prx_id}
        allInfo={allInfo}
        setAllInfo={setAllInfo}
        audit_type={row.auditType}
        setOpen={setOpen}
      />
    </Stack>
  );
}
