import * as React from "react";
import FormControl from "@mui/material/FormControl";
import { Box, TextField, Tooltip } from "@mui/material";
import { useRecoilState } from "recoil";
import { auditFilterAtom, staticDataAtom } from "../../atoms/atoms";

export default function PrescriberNameFilter({ data }) {
  const [filterOptions, setFilterOptions] = useRecoilState(auditFilterAtom);

  const handleChange = (event) => {
    const values = event.target.value; //.split(",").map((item) => item.trim());
    setFilterOptions((prev) => ({
      ...prev,
      prescriber_name: values,
    }));
  };

  return (
    <Box className="filterBar">
      <FormControl sx={{ m: 1, minWidth: 165, p: 1 }}>
        <Tooltip title="Enter multiple values separated by commas" arrow>
          <TextField
            value={filterOptions.prescriber_name || ""}
            onChange={handleChange}
            label="Prescribers"
            placeholder="Prescribers"
            style={{ width: 250, background: "whitesmoke" }}
          />
        </Tooltip>
      </FormControl>
    </Box>
  );
}
