import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useRecoilState } from "recoil";
import { adminAuditFilterAtom } from "../../../atoms/atoms";

export default function AdminAuditTypeFilter({ data, setFilterDataset }) {
  const [type, setType] = React.useState([]);
  const [historyFilters, setHistoryFilters] =
    useRecoilState(adminAuditFilterAtom);

  React.useEffect(() => {
    const uniqueTypes = new Set(data.map((d) => d.auditType));
    setType(Array.from(uniqueTypes));
  }, [data]);

  function onChange(newValue) {
    if (newValue !== null) {
      setHistoryFilters((prev) => ({
        ...prev,
        auditType: newValue,
        filtered: true,
      }));
    } else {
      setHistoryFilters((prev) => ({
        ...prev,
        auditType: "",
      }));
    }
  }

  return (
    <Stack sx={{ width: "33%" }} marginBottom={2}>
      <Typography variant="overline">Filter by Audit Type</Typography>
      <Autocomplete
        sx={{ background: "white" }}
        options={type}
        id="controlled-demo"
        value={historyFilters.auditType}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        renderInput={(params) => {
          return <TextField {...params} label="Audit Type" />;
        }}
      />
    </Stack>
  );
}
