import React from "react";


export default function Tags({ c, index }) {    
    return (
        <React.Fragment>        
            <div class="EncounterTags" style={{margin:"10px"}}>         
                {c.isReaudit ? 
            <TagBadge text="Re-Audit" style={{color:"#000000", background:"hsl(64, 88.10%, 60.60%)"}}/>: ""}
            </div>
        </React.Fragment>
        
    )
}


function TagBadge(props){
    const {style, text} = props;
    const defStyle = {
        color:"#000000",
        background:"#ffffff",
        borderRadius: "3px",
        padding:"4px",
        height:"23px",
        weight:"bold",
        margin:"2px",
        cursor: "context-menu",
        border:"1px solid grey"
    }
    const nowrap={
        "white-space":"nowrap"
    }

    let finalStyle = {...defStyle, ...style};

    
    return(
    
            <span class="badge" style={finalStyle}>{text}</span>
    
    );

};