import React from "react";
import PrescriberNameFilter from "./CompliancePrescriberNameFilter";
import ScriptFilter from "./ComplianceScriptFilter";
import {
  Box,
  Card,
  Typography,
  Collapse,
  Divider,
  Paper,
  Button,
  CardActionArea,
  CircularProgress,
} from "@mui/material";
import { Stack } from "@mui/system";
import DateRangePickerCustom from "./ComplianceDateRangePickerCustom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { ComplianceService } from "../../services/complianceService";
import { useRecoilState } from "recoil";
import {
  complianceInfoAtom,
  pageAtom,
  staticDataAtom,
} from "../../atoms/atoms";
import LoadingBlock from "./../LoadingBlock";
import RestrictedESPToggle from "./ComplianceRestrictedESPFilter";
import ExclusivePrescriberFilter from "./ComplianceExclusivePrescriberFilter";
import { prescriptionGen } from "../../utils/dictGen";
import { complianceFilterAtom } from "../../atoms/atoms";
import dayjs from "dayjs";
import CompliancePharmacyFilter from "./CompliancePharmacyFilter";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
const statusMap = {
  1: "New",
  2: "In Process",
  3: "Completed",
  4: "Submitted",
  5: "Cancelled",
  6: "Pending CE Review",
};
export default function ComplianceFilterBar({ reset, complianceCheckIn }) {
  const [data, setData] = useRecoilState(complianceInfoAtom);
  const [filters, setFilters] = React.useState([]);
  const [filterOptions, setFilterOptions] =
    useRecoilState(complianceFilterAtom);
  const [loading, setLoading] = React.useState(false);
  const [expanded, setExpanded] = React.useState(filterOptions.expanded);
  const [pages, setPages] = useRecoilState(pageAtom);
  const [restrictedToggle, setRestrictedToggle] = React.useState(
    filterOptions.rx_is_esp_restricted
  );
  const [exclusivePrescriber, setExclusivePrescriber] = React.useState(
    filterOptions.exclusive_prescriber
  );
  const [status, setStatus] = React.useState(
    statusMap[filterOptions.status_id]
  );
  const [scripts, setScripts] = React.useState(filterOptions.rx_id);
  const [staticData, setStaticData] = useRecoilState(staticDataAtom);
  const PER_PAGE = 15;

  const {
    getAllCompliance,
    getComplianceFiltered,
    getAllCompliancePrescribers,
    getAllCompliancePharmacies,
  } = ComplianceService();
  const searchFilterOptions = () => {
    setLoading(true);
    setFilterOptions((prev) => ({
      ...prev,
      filtered: true,
    }));

    getAllCompliance(
      PER_PAGE,
      pages.complianceFilteredPage,
      filterOptions,
      (data) => {
        const comps = data.all;
        const count = data.count;
        const pageCount = Math.ceil(parseInt(count) / PER_PAGE);
        setPages((prev) => ({
          ...prev,
          complianceFilteredNumberOfPages: pageCount,
        }));

        if (comps.length === 0) {
          setLoading(false);
          console.log("No data found");
          return;
        }
        const allInfo = prescriptionGen(comps);

        setData(allInfo);
        // setPages((prev) => ({
        //   ...prev,
        //   complianceFilteredNumberOfPages: data.pages,
        // }));

        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
    setFilterOptions((prev) => ({
      ...prev,
      expanded: true,
    }));
    // getAllCompliancePrescribers((data) => {
    //   const prescriberMap = data.map((p) => p.prescriber_name);
    //   setStaticData((prev) => ({
    //     ...prev,
    //     compliancePrescribers: prescriberMap,
    //   }));
    // });
    getAllCompliancePharmacies((data) => {
      const pharmacyMap = data.map((p) => p.pharmacy_name);
      setStaticData((prev) => ({
        ...prev,
        compliancePharmacies: pharmacyMap,
      }));
    });
  };

  const handleClear = () => {
    setFilterOptions({
      prescriber: "",
      status_id: "",
      rx_id: "",
      visit_id: "",
      start_date: dayjs().subtract(1, "month"),
      end_date: dayjs(),
      rx_is_esp_restricted: "",
      exclusive_prescriber: "",
      pharmacy: "",
      expanded: false,
      filtered: false,
    });
    setPages((prev) => ({
      ...prev,
      filteredPage: 1,
      page: 1,
      filteredNumberOfPages: 1,
      complianceFilteredPage: 1,
      complianceFilteredNumberOfPages: 1,
    }));
    complianceCheckIn();
    reset();
  };

  React.useEffect(() => {
    if (!filterOptions.filtered) return;
    searchFilterOptions();
  }, [pages.complianceFilteredPage]);

  React.useEffect(() => {
    setFilters([
      { filter: "Prescriber", value: filterOptions.prescriber },
      { filter: "Prescription Number", value: filterOptions.rx_id },
      {
        filter: "Dates",
        value: filterOptions.start_date
          ? [
              `Start: ${new Date(filterOptions.start_date).toDateString()} \n`,
              `End: ${new Date(filterOptions.end_date).toDateString()}`,
            ]
          : [],
      },
      {
        filter: "Exclusive Prescriber",
        value: filterOptions.exclusive_prescriber,
      },
      { filter: "Restricted ESP", value: filterOptions.is_esp_restricted },
      { filter: "Pharmacy", value: filterOptions.pharmacy },
    ]);
  }, [filterOptions]);

  React.useEffect(() => {
    //Setting static filter options on load
  }, []);

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <Box pt={5}>
      <Card>
        <CardActionArea onClick={handleExpandClick}>
          <Typography
            color="#282c34"
            variant={"overline"}
            paddingRight={5}
            visibility={"visible"}
            sx={{ fontSize: 14 }}
          >
            Compliance Audit Filters
          </Typography>

          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            sx={{ fontFamily: "unset", fontSize: "medium" }}
          >
            <ExpandMoreIcon titleAccess="Audit Filters"></ExpandMoreIcon>
          </ExpandMore>
        </CardActionArea>
        <Divider orientation="horizontal" flexItem />
        <Stack direction={"row"} justifyContent={"space-evenly"}>
          <IconButton
            onClick={handleClear}
            aria-label="delete"
            color="primary"
            sx={{ justifyContent: "flex-start", fontSize: 14 }}
          >
            <DeleteIcon fontSize="medium" />
          </IconButton>

          {filters.map((f, index) => {
            return (
              <Stack justifyItems={"center"} alignItems={"center"}>
                <Typography
                  variant="h3"
                  key={index}
                  padding={3}
                  sx={{ fontSize: 16, display: "flex" }}
                >
                  {f.filter}:
                </Typography>
                <Divider orientation="horizontal" flexItem />
                {/* {f.value.map((v, index) => {
                  return ( */}
                <Typography
                  variant="overline"
                  key={index}
                  padding={1}
                  sx={{
                    fontSize: 12,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {f.value && Array.isArray(f.value) ? (
                    <Stack direction={"column"}>
                      {f.value.map((v, index) => (
                        <Typography variant="overline" key={index}>
                          {v}
                        </Typography>
                      ))}
                    </Stack>
                  ) : (
                    f.value
                  )}
                </Typography>
                {/* );
                })} */}
              </Stack>
            );
          })}
        </Stack>
      </Card>
      <Collapse in={expanded} timeout="auto">
        <Paper>
          <DateRangePickerCustom
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
          />
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              backgroundColor: "#B9D7EA",
              pb: 1,
            }}
          >
            <Stack spacing={1}>
              <Stack direction={"row"} spacing={1}>
                <Typography
                  component="div"
                  sx={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <PrescriberNameFilter
                    data={data}
                    setData={setData}
                    setFilterOptions={setFilterOptions}
                  />
                  <ExclusivePrescriberFilter
                    setExclusivePrescriber={setExclusivePrescriber}
                    exclusivePrescriber={exclusivePrescriber}
                    setFilterOptions={setFilterOptions}
                  />
                </Typography>
                <Typography
                  component="div"
                  sx={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <ScriptFilter
                    setScripts={setScripts}
                    filterOptions={filterOptions}
                    setFilterOptions={setFilterOptions}
                  />
                  <CompliancePharmacyFilter />
                </Typography>
              </Stack>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <RestrictedESPToggle
                  restrictedToggle={restrictedToggle}
                  setRestrictedToggle={setRestrictedToggle}
                  setFilterOptions={setFilterOptions}
                />
              </Box>
            </Stack>
          </Card>

          <Stack direction={"row"} justifyContent={"center"}>
            <Button
              variant="contained"
              sx={{ m: 1 }}
              onClick={searchFilterOptions}
            >
              Submit
            </Button>
            <Button variant="contained" sx={{ m: 1 }} onClick={handleClear}>
              Clear All
            </Button>
          </Stack>
        </Paper>
      </Collapse>
    </Box>
  );
}
