import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Button, CardHeader, CircularProgress } from "@mui/material";
import AuditHistoryHeader from "../components/history/AuditHistoryHeader";
import { AuditService } from "../services/auditService";
import { UserContext } from "../contexts/userContext";
import AuditorFilter from "../components/history/AuditorFilter";
import RxFilter from "../components/history/HistoryRxFilter";
import AuditDateRangePicker from "../components/history/AuditDateRangePicker";
import { historyGen } from "../utils/dictGen";
import { useRecoilState } from "recoil";
import { historyFilterAtom } from "../atoms/atoms";
import fileDownload from "js-file-download";
import HistoryTypeFilter from "../components/history/HistoryTypeFilter";
import HistoryOutcomeFilter from "../components/history/HistoryOutcomeFilter";
import ErrorPage from "./ErrorPage";

export default function History() {
  const [historyFilters, setHistoryFilters] = useRecoilState(historyFilterAtom);
  const [encounters, setEncounters] = React.useState([]);
  const [referrals, setReferrals] = React.useState([]);
  const [audits, setAudits] = React.useState([]);
  const [compliance, setCompliance] = React.useState([]);
  const [dataset, setDataset] = React.useState([]);
  const [dateRange, setDateRange] = React.useState([
    historyFilters.start_date,
    historyFilters.end_date,
  ]);
  const [loading, setLoading] = React.useState(false);
  const [load1, setLoad1] = React.useState(false);
  const [load2, setLoad2] = React.useState(false);
  const [load3, setLoad3] = React.useState(false);
  const [load4, setLoad4] = React.useState(false);
  const {
    getAllAuditedAudits,
    getAllAuditedEncounters,
    getAllAuditedReferrals,
    exportRecords,
    getAllAuditedCompliance,
  } = AuditService();
  const userCtx = React.useContext(UserContext);
  const user = userCtx.user;
  const userPermissions = user.user_secondary_roles.filter((r) => r == 7);

  const resetData = () => {
    setDataset([]);
    setEncounters([]);
    setAudits([]);
    setCompliance([]);
    setReferrals([]);
    setDateRange([null, null]);
    setHistoryFilters((prev) => ({
      ...prev,
      auditor: "",
      rx_id: "",
      auditType: "",
      outcome: "",
      start_date: null,
      end_date: null,
      filtered: false,
    }));
  };
  const getData = () => {
    setLoading(true);
    resetData();
    getAllAuditedEncounters(
      !user.hierarchy ? [user.user_id] : [...user.hierarchy, user.user_id],
      historyFilters.start_date,
      historyFilters.end_date,
      historyFilters.auditor,
      historyFilters.rx_id,
      historyFilters.auditType,
      historyFilters.outcome,
      (data) => {
        const allInfo = historyGen(data, "Encounter");
        setEncounters(allInfo);
        setLoad1(true);
      },
      (err) => {
        console.log(err);
        setLoad1(true);
      }
    );

    getAllAuditedReferrals(
      !user.hierarchy ? [user.user_id] : [...user.hierarchy, user.user_id],
      historyFilters.start_date,
      historyFilters.end_date,
      historyFilters.auditor,
      historyFilters.rx_id,
      historyFilters.auditType,
      historyFilters.outcome,
      (data) => {
        const allInfo = historyGen(data, "Referral");
        setReferrals(allInfo);
        setLoad2(true);
      },
      (err) => {
        console.log(err);
        setLoad2(true);
      }
    );
    getAllAuditedAudits(
      !user.hierarchy ? [user.user_id] : [...user.hierarchy, user.user_id],
      historyFilters.start_date,
      historyFilters.end_date,
      (data) => {
        const allInfo = historyGen(data, "View");
        setAudits(allInfo);
        setLoad3(true);
      },
      (err) => {
        console.log(err);
        setLoad3(true);
      }
    );
    getAllAuditedCompliance(
      !user.hierarchy ? [user.user_id] : [...user.hierarchy, user.user_id],
      historyFilters.start_date,
      historyFilters.end_date,
      historyFilters.auditor,
      historyFilters.rx_id,
      historyFilters.auditType,
      historyFilters.outcome,
      (data) => {
        const allInfo = historyGen(data, "Compliance");
        setCompliance(allInfo);
        setLoad4(true);
      },
      (err) => {
        console.log(err);
        setLoad4(true);
      }
    );
  };

  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    setLoad1(false);
    setLoad2(false);
    setLoad3(false);
    setLoad4(false);
    //getData();
  }, [historyFilters]);

  React.useEffect(() => {
    if (load1 && load2 && load3 && load4) setLoading(false);
  }, [load1, load2, load3, load4]);

  React.useEffect(() => {
    setDataset([...encounters, ...referrals, ...audits, ...compliance]);
  }, [encounters, referrals, audits, compliance]);

  React.useEffect(() => {
    if (
      !historyFilters.auditor &&
      !historyFilters.rx_id &&
      !historyFilters.auditType &&
      !historyFilters.outcome &&
      !(historyFilters.start_date && historyFilters.end_date)
    ) {
      setHistoryFilters((prev) => ({
        ...prev,
        filtered: false,
      }));
    }
  }, [
    historyFilters.auditor,
    historyFilters.rx_id,
    historyFilters.auditType,
    historyFilters.outcome,
    historyFilters.start_date,
    historyFilters.end_date,
  ]);

  // React.useEffect(() => {
  //   let data = [...dataset];
  //   if (historyFilters.filtered) {
  //     if (historyFilters.auditor) {
  //       data = data.filter((d) => d.auditor == historyFilters.auditor);
  //     }
  //     if (historyFilters.rx_id) {
  //       data = data.filter((d) => d.pk.rx_number == historyFilters.rx_id);
  //     }
  //     if (historyFilters.auditType) {
  //       data = data.filter((d) => d.auditType == historyFilters.auditType);
  //     }
  //     if (historyFilters.outcome) {
  //       data = data.filter(
  //         (d) => d.outcome && d.outcome == historyFilters.outcome
  //       );
  //     }
  //   } else {
  //     data = [...dataset];
  //   }

  //   setFilterDataset(() => data);
  // }, [dataset]);
  const handleReset = async () => {
    resetData();
    setHistoryFilters((prev) => ({
      ...prev,
      auditor: "",
      rx_id: "",
      auditType: "",
      outcome: "",
      start_date: null,
      end_date: null,
      filtered: false,
    }));
    getData();
  };

  const handleSubmit = () => {
    getData();
  };
  const handleExport = () => {
    setLoading(true);
    const querySet = dataset.map((f) => {
      let obj = {
        rx_prx_id: f.rx_prx_id,
        auditor: f.auditor,
        outcome: f.outcome,
        audit_type: f.auditType,
        status_id: f.status,
        audit_date_created: f.auditDateCreated,
        audit_date_updated: f.auditDateUpdated,
        audit_time: f.auditTimeMinutes,
      };
      return obj;
    });

    exportRecords(
      querySet,
      (data) => {
        fileDownload(
          data,
          `History_Report_${new Date().toLocaleDateString()}.xlsx`
        );
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  };

  if (userPermissions.length < 1) {
    return <ErrorPage />;
  }

  return (
    <Stack>
      <Box sx={{ width: 1, height: 400 }}>
        <CardHeader
          title="Audit History"
          titleTypographyProps={{
            fontSize: 25,
            variant: "overline",
            textAlign: "center",
            fontStyle: "underline",
          }}
        />
        <Stack
          sx={{ display: "flex", background: "#B9D7EA" }}
          alignItems={"center"}
        >
          <AuditorFilter data={dataset} />
          <RxFilter data={dataset} />

          <HistoryTypeFilter data={dataset} />
          <HistoryOutcomeFilter data={dataset} />
          <AuditDateRangePicker
            original={dataset}
            data={dataset}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
          <Stack direction={"horizontal"}>
            <Button sx={{ m: 1 }} variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
            <Button sx={{ m: 1 }} variant="contained" onClick={handleReset}>
              {" "}
              Reset All{" "}
            </Button>
          </Stack>
        </Stack>
        <Button
          onClick={handleExport}
          sx={{ justifySelf: "end", margin: 2 }}
          variant="contained"
        >
          Download Report
        </Button>

        <AuditHistoryHeader
          audits={audits}
          encounters={encounters}
          referrals={referrals}
          compliances={compliance}
          loading={loading}
          filter={dataset.map((f) => f.rx_prx_id)}
        />
      </Box>
    </Stack>
  );
}
