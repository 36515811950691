import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { UserContext } from "../contexts/userContext";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { pageAtom } from "../atoms/atoms";

const page_dict = {
  4: "Encounter Audit",
  5: "Referral Audit",
  6: "Compliance Audit",
  7: "History",
};
const settings = ["Logout", "Settings"];

const ResponsiveAppBar = ({ onAppBarHeightChange }) => {
  const [pageRef, setPageRef] = useRecoilState(pageAtom);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElAdmin, setAnchorElAdmin] = React.useState(null);
  const [activePage, setActivePage] = React.useState(pageRef.activePage);
  const appBarRef = React.useRef(null);
  const userCtx = React.useContext(UserContext);
  const { user } = userCtx;
  const navigate = useNavigate();
  const prefix = user.dbprefix;
  const available_pages = user.user_secondary_roles;
  const pages = available_pages.map((p) => page_dict[p]);

  React.useEffect(() => {
    const appBarHeight = appBarRef.current.clientHeight;
    onAppBarHeightChange(appBarHeight);
  }, [onAppBarHeightChange]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenAdminMenu = (event) => {
    setAnchorElAdmin(event.currentTarget);
  };

  const handleCloseAdminMenu = () => {
    setAnchorElAdmin(null);
  };

  const handleAdminMenuClick = (path) => {
    navigate(`/${path}`);
    handleCloseAdminMenu();
  };
  const handleLogout = (setting) => {
    if (setting.target.textContent == "Logout") {
      userCtx.logout();
      navigate(`/Login`);
    } else {
      navigate(`/${setting.target.textContent}`);
    }
  };

  const goToPage = (page) => {
    let pageref;
    switch (page) {
      case "Encounter Audit":
        pageref = "Audit";
        break;
      case "Compliance Audit":
        pageref = "Compliance";
        break;
      case "Referral Audit":
        pageref = "Referral";
        break;
      default:
        pageref = page;
        break;
    }
    //console.log(`/${pageref}`);
    navigate(`/${pageref}`);
    setPageRef((prev) => ({
      ...prev,
      activePage: page,
    }));
  };

  return (
    <AppBar position="fixed" ref={appBarRef}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img
            loading="lazy"
            src="https://www.proxsysrx.com/wp-content/uploads/2021/09/white-ProxsysRx-Logo.png"
            width={"10%"}
            height={"10%"}
          ></img>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          ></Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            ></IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none", padStart: 1 },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
              {user.role_id == 3 && (
                <MenuItem key={"Admin"} onClick={handleCloseNavMenu}>
                  <Typography textAlign={"center"}>Admin</Typography>
                </MenuItem>
              )}
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
            onClick={() => navigate(`/Audit`)}
          >
            PROXSYSRX
          </Typography>
          <Box sx={{ flexGrow: 2, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => {
                  handleCloseNavMenu();
                  goToPage(page);
                }}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  marginLeft: 5,
                  backgroundColor:
                    page === pageRef.activePage
                      ? "rgba(255, 255, 255, 0.2)"
                      : "inherit",
                }}
              >
                {page}
              </Button>
            ))}
            {user.role_id == 3 && (
              <Box>
                <Button
                  onClick={handleOpenAdminMenu}
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    marginLeft: 5,
                  }}
                >
                  Admin
                </Button>
                <Menu
                  id="admin-menu"
                  anchorEl={anchorElAdmin}
                  open={Boolean(anchorElAdmin)}
                  onClose={handleCloseAdminMenu}
                >
                  <MenuItem onClick={() => handleAdminMenuClick("Admin")}>
                    Admin Options
                  </MenuItem>
                  <MenuItem onClick={() => handleAdminMenuClick("AdminAudit")}>
                    Unsubmit Records
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Random" src="" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography
                    onClick={(e) => handleLogout(e)}
                    textAlign="center"
                  >
                    {setting}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
