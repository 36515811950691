import React from "react";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { ComplianceService } from "../../services/complianceService";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import LoadingBlock from "../LoadingBlock";
import { useRecoilState } from "recoil";
import { complianceInfoAtom } from "../../atoms/atoms";

export default function ExpandComplianceCard({ c, index }) {
  const [cardInfo, setCardInfo] = useRecoilState(complianceInfoAtom);
  const { markComplianceAsAudited, getSingleCompliance } = ComplianceService();
  const [loading, setLoading] = React.useState(false);
  const [notesArray, setNotesArray] = React.useState([]);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [readOnly, setReadOnly] = React.useState(false);
  const [formValues, setFormValues] = React.useState({
    compliance_status_id: "",
    compliance_qualified_correctly: "",
    compliance_notes: "",
  });

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
  });

  React.useEffect(() => {
    if(c.isReaudit){
      setReadOnly(true);
    }
    getSingleCompliance(c.rx_prx_id, (data) => {
      if(data.qualified){
        setFormValues((prev) => ({ ...prev, compliance_qualified_correctly : data.qualified }));
      }
      setNotesArray(data.notes);
     
    }, (err) => {
      console.log(err);
    });
  },[]);

  React.useEffect(() => {
    if (!c) return;
    
    

    if (c.qualified_correctly) {
      setFormValues((prev) => ({
        ...prev,
        compliance_qualified_correctly: c.qualified_correctly.toString(),
      }));
    }
    if (formValues.compliance_qualified_correctly == "true") {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
      if (formValues.compliance_notes) {
        setIsDisabled(false);
      }
    }
  }, [formValues]);

  const setEditable = () => {
    setReadOnly(false);
  };
  const handleSubmit = () => {
    setLoading(true);
    markComplianceAsAudited(
      c.rx_prx_id,
      formValues.compliance_qualified_correctly,
      formValues.compliance_notes,
      () => {
        setLoading(false);
        setCardInfo((prev) => {
          const newArray = prev.filter((p) => p.rx_prx_id != c.rx_prx_id);
          return newArray;
        });

        swalWithBootstrapButtons.fire(
          "Submitted!",
          `Compliance Audit for RX: ${c.pk.rx_number} has been submitted.`,
          "success"
        );
      },
      () => {
        setLoading(false);
        swalWithBootstrapButtons.fire(
          "Error.",
          `There was an error processing your request.`,
          "error"
        );
      }
    );
  };
  const handleCancel = () => {
    setFormValues({
      compliance_status_id: "",
      compliance_qualified_correctly: "",
      compliance_notes: "",
    });
  };
  if (loading) return <LoadingBlock />;
  return (
    <Card sx={{ width: "100%", height: "100%" }}>
      <CardContent sx={{ marginBottom: 1 }}>
        <Stack direction={"row"}>
          <Stack padding={1} sx={{ width: "20%", height: "100%" }}>
            <FormControl
            disabled = {readOnly}
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  compliance_qualified_correctly: e.target.value,
                }))
              }
            >
              <Typography>Qualified Correctly?</Typography>
              <RadioGroup
                
                aria-labelledby="radio-button-group"
                name="qualified-correctly"
                value={formValues.compliance_qualified_correctly}
              >
                <FormControlLabel
                  value={"true"}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value={"false"}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Stack>
          <Divider orientation="vertical" flexItem sx={{ padding: 2 }} />
          <Stack>
            <TextField
              disabled = {readOnly}
              required
              id="compliance_notes"
              variant="standard"
              placeholder="Compliance Notes"
              value={formValues.compliance_notes}
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  compliance_notes: e.target.value,
                }))
              }
              sx={{ margin: 1, width: "50%" }}
            />
            <Typography><b>Notes:</b><hr /></Typography>
            {notesArray.map((n) => (
              <Typography>
                <b>{`${n.note}`}</b> <br />
                 {`${n.user_first_name} ${n.user_last_name}`} - {`${dayjs(n.created).format("mm/DD/YY : hh:mm a")}`} 
                 <hr />
              </Typography>
            ))}
          </Stack>
          <Divider orientation="vertical" flexItem sx={{ padding: 2 }} />
          {!readOnly &&  (
          <Stack sx={{ width: "20%", height: "100%" }}>
            <Button disabled={isDisabled} onClick={handleSubmit}>
              Submit
            </Button>
            <Button onClick={handleCancel}>Clear</Button>
          
          </Stack>)}
          {readOnly && (
            <Stack sx={{ width: "20%", height: "100%" }}>
                <Button onClick={setEditable}>
                  Edit
                </Button>

              </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
