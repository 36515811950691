import React from "react";

import { useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/userContext";
import LoadingBlock from "../components/LoadingBlock";

export default function SignOutScreen() {
  const { user, logout } = React.useContext(UserContext);
  const navigate = useNavigate();
  React.useEffect(() => {
    logout();
    navigate("/Login");
  }, []);
  return <LoadingBlock />;
}
