import * as React from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { Card, CardContent, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import dayjs from "dayjs";

export default function DateRangePickerCustom({
  filterOptions,
  setFilterOptions,
}) {
  const [value, setValue] = React.useState([
    dayjs(filterOptions.start_date),
    dayjs(filterOptions.end_date),
  ]);
  const [disable, setDisable] = React.useState(false);
  const handleSelect = (date) => {
    setValue(date);
    let fromDate = new Date(date[0].$d);
    fromDate.setHours(0, 0, 0, 0);
    fromDate = fromDate.toISOString();
    let toDate = new Date(date[1].$d);
    toDate.setHours(23, 59, 59, 999);
    toDate = toDate.toISOString();
    setFilterOptions((prev) => ({
      ...prev,
      start_date: fromDate,
      end_date: toDate,
    }));

    setDisable(true);
  };

  return (
    <Card sx={{ background: "#B9D7EA" }}>
      <CardContent>
        <Typography variant="overline">Filter by Date Dispensed</Typography>
        <Stack direction={"row"} justifyContent="center" spacing={2}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{ start: "Start Date", end: "End Date" }}
          >
            <DateRangePicker
              value={value}
              disabled={disable}
              onChange={handleSelect}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField
                    {...startProps}
                    sx={{ background: "whitesmoke" }}
                    variant="outlined"
                  />
                  <Box sx={{ mx: 1 }}> to </Box>
                  <TextField
                    {...endProps}
                    sx={{ background: "whitesmoke" }}
                    variant="outlined"
                  />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </Stack>
      </CardContent>
    </Card>
  );
}
