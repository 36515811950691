import { baseApi } from "./baseService";
import { LogOut } from "../utils/logOut";

export function ComplianceService() {
  const { loggedOut } = LogOut();
  const API = {
    getAllCompliance: "/compliance/all",
    markComplianceAsAudited: "/compliance/audited",
    getNumberOfCompliancePages: "/compliance/count",
    checkOutRecord: "/compliance/checkOut",
    checkInRecord: "/compliance/checkIn",
    checkInAllRecords: "/compliance/checkInAll",
    complianceFormCounts: "/compliance/formCounts",
    getFiltered: "/compliance/filter",
    getAllPrescribers: "/compliance/prescribers",
    getAllPharmacies: "/compliance/pharmacies",
    checkInRecordAdmin: "/compliance/checkInAdmin",
    getSingle: "/compliance/get",
  };

  const getSingleCompliance = (rx_prx_id, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getSingle, { rx_prx_id })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const getAllCompliance = (limit, offset, filters, onSuccess, onError) => {
    const payload = {
      limit,
      offset,
      ...filters,
    };

    baseApi().then((api) =>
      api
        .post(API.getAllCompliance, payload)
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const markComplianceAsAudited = async (
    rx_prx_id,
    qualified,
    notes,
    onSuccess,
    onError
  ) => {
    baseApi().then((api) =>
      api
        .post(API.markComplianceAsAudited, { rx_prx_id, qualified, notes })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const getNumberOfCompliancePages = (onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getNumberOfCompliancePages)
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const checkOutComplianceRecord = (rx_prx_id, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.checkOutRecord, {
          rx_prx_id,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const checkInComplianceRecord = (rx_prx_id, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.checkInRecord, {
          rx_prx_id,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const checkInComplianceRecordAdmin = (
    compliance_record_id,
    onSuccess,
    onError
  ) => {
    baseApi().then((api) =>
      api
        .post(API.checkInRecordAdmin, {
          compliance_record_id,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          onError && onError(response);
        })
    );
  };
  const checkInAllComplianceRecords = (user_id, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.checkInAllRecords, {
          user_id,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };
  const getComplianceFormCounts = (rx_prx_id, onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.complianceFormCounts, {
          rx_prx_id,
        })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const getComplianceFiltered = (
    filterOptions,
    limit,
    offset,
    onSuccess,
    onError
  ) => {
    baseApi().then((api) =>
      api
        .post(API.getAllCompliance, { ...filterOptions, limit, offset })
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };

  const getAllCompliancePrescribers = (onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getAllPrescribers)
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };
  const getAllCompliancePharmacies = (onSuccess, onError) => {
    baseApi().then((api) =>
      api
        .post(API.getAllPharmacies)
        .then(({ data }) => {
          onSuccess(data);
        })
        .catch(({ response }) => {
          console.log(response);
          loggedOut(response);
          onError && onError(response);
        })
    );
  };
  return {
    getAllCompliance,
    markComplianceAsAudited,
    getNumberOfCompliancePages,
    checkOutComplianceRecord,
    checkInComplianceRecord,
    checkInAllComplianceRecords,
    getComplianceFormCounts,
    getAllCompliancePrescribers,
    getComplianceFiltered,
    checkInComplianceRecordAdmin,
    getAllCompliancePharmacies,
    getSingleCompliance,
  };
}
