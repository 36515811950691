import * as React from "react";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { tenantValidate } from "../services/loginService";
import cookies from "js-cookie";

export default function LandingPage({ setSelected }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [tenant, setTenant] = React.useState();

  const handleClick = (event) => {
    let nav;
    tenantValidate(
      tenant,
      (data) => {
        if (!data) return alert("Invalid tenant id");
        nav = data;
        setSelected(true);
        cookies.set("tenant", nav, { expires: 30 });
        navigate(`/Login`);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission

    handleClick();
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack direction={"column"} alignItems={"center"}>
        <img
          src="https://www.proxsysrx.com/wp-content/uploads/2021/09/ProxsysRx-Logo.png"
          width={"200"}
        />
        <Typography>Please enter your tenant id to login:</Typography>
        <form onSubmit={handleSubmit}>
          <Stack>
            <TextField
              required
              id="tenant-text"
              variant="outlined"
              sx={{ m: 1 }}
              onChange={(e) => setTenant(e.target.value)}
            />
            <Button onClick={handleClick} variant="outlined">
              Submit
            </Button>
          </Stack>
        </form>
        <Typography variant="subtitle2">
          If you don't know your tenant id, please email ProxsysRx support at
        </Typography>
        <Typography variant="subtitle2">
          <a href="mailto:support@proxsysrx.com">support@proxsysrx.com</a>
        </Typography>
      </Stack>
    </ThemeProvider>
  );
}
