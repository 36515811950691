import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { api } from "./loginService";
const urls = require("../config").default.urls;
var baseurl = `${urls.base}/`;
export const baseApi = async () => {
  var user = null;

  try {
    let userFromCookie = Cookies.get("prorx_user");
    user = userFromCookie && JSON.parse(userFromCookie);
  } catch (error) {
    console.log(
      "Something bad happened while trying to get the user api key from local storage."
    );
    console.log(error);
    showLoggedOutMessage();
  }

  api.defaults.baseURL = baseurl;
  return api;
};
const showLoggedOutMessage = () => {
  Swal.fire({
    title: "Logged Out",
    text: "You have been logged out.",
    icon: "warning",
    confirmButtonText: "OK",
  }).then((result) => {
    if (result.isConfirmed || result.isDismissed) {
      window.location.href = `${baseurl}/LogOut`;
    }
  });
};
export { showLoggedOutMessage };
