import React from "react";
import { AuditService } from "../services/auditService";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Paper,
  Popover,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { UserContext } from "../contexts/userContext";
import AdminCheckIn from "../components/admin/AdminCheckIn";
import { QueueContext } from "../contexts/queueContext";
import AdminAddUser from "../components/admin/AdminAddUser";
import { AdminService } from "../services/adminService";
import AdminUpdateUser from "../components/admin/AdminUpdateUser";
import AdminExportTPAReport from "../components/admin/AdminTPAExport";
import AdminExportComplianceReport from "../components/admin/AdminComplianceExport";

export default function AdminPage() {
  const userCtx = React.useContext(UserContext);
  const { user } = userCtx;
  const qCTX = React.useContext(QueueContext);
  const { queueMessages } = qCTX;
  const [checkedOutRecords, setCheckedOutRecords] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [allUsers, setAllUsers] = React.useState([]);
  const [editUser, setEditUser] = React.useState(null);
  const topAnchorRef = React.useRef(null);
  const { getAllUsers, getAllCheckedOutRecords } = AdminService();

  React.useEffect(() => {
    setLoading(true);
    getAllCheckedOutRecords(
      (data) => {
        setCheckedOutRecords(data);
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, [
    queueMessages.NonMatchMessages,
    queueMessages.NonMatch_ReferralMessages,
    queueMessages.ComplianceMessages,
    setCheckedOutRecords,
  ]);

  React.useEffect(() => {
    getAllUsers(
      (data) => setAllUsers(data),
      (err) => console.log(err)
    );
  }, [editUser]);

  function Row(props) {
    const { row, index } = props;

    return (
      <React.Fragment>
        <TableRow
          className={index % 2 === 0 ? "EvenRow" : ""}
          sx={{ "& > *": { borderBottom: "unset" } }}
        >
          <TableCell>{row.user_id}</TableCell>
          <TableCell>{row.user_first_name}</TableCell>
          <TableCell>{row.user_last_name}</TableCell>
          <TableCell>{row.username}</TableCell>
          <TableCell>{row.active ? "Active" : "Deactivated"}</TableCell>
          <TableCell>
            <Button onClick={(e) => handleEditClick(e, row.user_id)}>
              Edit User
            </Button>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const handleClick = (e) => {
    setAnchorEl(topAnchorRef.current);
  };
  const handleEditClick = (e, id) => {
    setEditUser(id);
    setAnchorEl2(e.currentTarget);
  };
  const handleExportClick = (e) => {
    setAnchorEl3(topAnchorRef.current);
  };
  const handleComplianceExportClick = (e) => {
    setAnchorEl4(topAnchorRef.current);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };
  const handleClose4 = () => {
    setAnchorEl4(null);
  };
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);
  const open4 = Boolean(anchorEl4);
  const id = open ? "simple-popover" : undefined;
  const id2 = open2 ? "edit-popover" : undefined;
  const id3 = open3 ? "export-popover" : undefined;
  const id4 = open4 ? "export-compliance-popover" : undefined;
  if (!user || user.role_id != 3) return;
  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <Stack>
      <div
        ref={topAnchorRef}
        style={{ position: "absolute", top: 0, left: 0, height: 0, width: 0 }}
      ></div>

      <Box sx={{ width: 1, height: 400 }}>
        <CardHeader
          title="Admin"
          titleTypographyProps={{
            fontSize: 25,
            variant: "overline",
            textAlign: "center",
            fontStyle: "underline",
          }}
        />

        <Paper sx={{ m: 4, p: 4 }}>
          <Stack alignItems={"center"} p={1}>
            <Card>
              <CardHeader title="Current Users" />
              <CardContent>
                <TableContainer component={Card}>
                  <TableHead>
                    <TableRow className="userList">
                      <TableCell component="th" scope="row">
                        User ID
                      </TableCell>
                      <TableCell component="th" scope="row">
                        First Name
                      </TableCell>
                      <TableCell component="th" scope="row">
                        Last Name
                      </TableCell>
                      <TableCell component="th" scope="row">
                        Email
                      </TableCell>
                      <TableCell component="th" scope="row">
                        State
                      </TableCell>
                      <TableCell component="th" scope="row"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allUsers.map((u, index) => (
                      <Row key={index} row={u} index={index} />
                    ))}
                  </TableBody>
                </TableContainer>
              </CardContent>
            </Card>
            <Button onClick={handleClick} variant="contained" sx={{ m: 2 }}>
              Add a User
            </Button>
            <Button
              onClick={handleExportClick}
              variant="contained"
              sx={{ m: 2 }}
            >
              Download TPA Export
            </Button>
            <Button
              onClick={handleComplianceExportClick}
              variant="contained"
              sx={{ m: 2 }}
            >
              Download Compliance Export
            </Button>
          </Stack>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            sx={{ width: "100%" }}
          >
            <AdminAddUser
              setAnchorEl={setAnchorEl}
              sx={{ display: "flex", background: "#B9D7EA" }}
            />
          </Popover>
          <Popover
            id={id2}
            open={open2}
            anchorEl={anchorEl2}
            onClose={handleClose2}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            sx={{ width: "100%" }}
          >
            <AdminUpdateUser
              setAnchorEl={setAnchorEl2}
              user_id={editUser}
              setEditUser={setEditUser}
            />
          </Popover>
          <Popover
            id={id3}
            open={open3}
            anchorEl={anchorEl3}
            onClose={handleClose3}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            sx={{ width: "100%" }}
          >
            <AdminExportTPAReport setAnchorEl={setAnchorEl3} />
          </Popover>
          <Popover
            id={id4}
            open={open4}
            anchorEl={anchorEl4}
            onClose={handleClose4}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            sx={{ width: "100%" }}
          >
            <AdminExportComplianceReport setAnchorEl={setAnchorEl4} />
          </Popover>
        </Paper>
        <Divider />
        <AdminCheckIn
          sx={{ display: "flex", background: "#B9D7EA" }}
          checkedOutRecords={checkedOutRecords}
          setCheckedOutRecords={setCheckedOutRecords}
        />
      </Box>
    </Stack>
  );
}
