import React from "react";
import {
  CircularProgress,
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { NonmatchedService } from "../../services/nonmatchedService";
import { AuditService } from "../../services/auditService";
import { UserContext } from "../../contexts/userContext";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import ArrowDropUpSharpIcon from "@mui/icons-material/ArrowDropUpSharp";
import { useRecoilState } from "recoil";
import { historyFilterAtom } from "../../atoms/atoms";

export default function AuditHistoryHeader({
  encounters,
  referrals,
  compliances,
  audits,
  loading,
  filter,
}) {
  const [historyFilter, setHistoryFilter] = useRecoilState(historyFilterAtom);
  const userCtx = React.useContext(UserContext);
  const [ascDescSort, setAscDescSort] = React.useState(true);
  const [showSortIcon, setShowSortIcon] = React.useState(false);
  const [encounterInfo, setEncounterInfo] = React.useState([]);
  const [referralInfo, setReferralInfo] = React.useState([]);
  const [complianceInfo, setComplianceInfo] = React.useState([]);
  const [auditInfo, setAuditInfo] = React.useState([]);
  const [allInfo, setAllInfo] = React.useState([]);
  const [userRoles, setUserRoles] = React.useState([]);
  const { checkInAllRecords } = NonmatchedService();

  React.useEffect(() => {
    setUserRoles(userCtx.getRoles());
    checkInAllRecords(
      userCtx.user.user_id,
      (data) => {
        console.log("records checked in");
      },
      (err) => console.log(err)
    );
  }, []);

  React.useEffect(() => {
    const allEmpty =
      (!encounters || encounters.length === 0) &&
      (!referrals || referrals.length === 0) &&
      (!compliances || compliances.length === 0) &&
      (!audits || audits.length === 0);

    if (allEmpty) {
      //console.log("All inputs empty, resetting states");
      setEncounterInfo([]);
      setReferralInfo([]);
      setComplianceInfo([]);
      setAuditInfo([]);
      setAllInfo([]);
    }
  }, [encounters, referrals, compliances, audits]);

  React.useEffect(() => {
    if (encounters?.length > 0) {
      //console.log("Setting encounterInfo:", encounters);
      setEncounterInfo(encounters);
    }
    if (referrals?.length > 0) {
      //console.log("Setting referralInfo:", referrals);
      setReferralInfo(referrals);
    }
    if (compliances?.length > 0) {
      //console.log("Setting complianceInfo:", compliances);
      setComplianceInfo(compliances);
    }
    if (audits?.length > 0) {
      //console.log("Setting auditInfo:", audits);
      setAuditInfo(audits);
    }
  }, [encounters, referrals, compliances, audits]);
  //merging into one
  React.useEffect(() => {
    const hasData =
      encounterInfo.length > 0 ||
      referralInfo.length > 0 ||
      auditInfo.length > 0 ||
      complianceInfo.length > 0;

    //console.log("Has Data:", hasData);

    if (hasData) {
      const combinedInfo = [
        ...encounterInfo,
        ...referralInfo,
        ...auditInfo,
        ...complianceInfo,
      ];

      //console.log("Combined Info length:", combinedInfo.length);

      if (historyFilter.filtered && filter) {
        const filteredInfo = combinedInfo.filter((a) =>
          filter.includes(a.rx_prx_id)
        );
        //console.log("Setting filtered info, length:", filteredInfo.length);
        setAllInfo(filteredInfo);
      } else {
        //console.log("Setting unfiltered info, length:", combinedInfo.length);
        setAllInfo(combinedInfo);
      }
    }
  }, [
    encounterInfo,
    referralInfo,
    auditInfo,
    complianceInfo,
    historyFilter.filtered,
    filter,
  ]);
  // console.log(allInfo);
  function handleAuditDateSort() {
    setShowSortIcon(true);
    setAscDescSort(!ascDescSort);
    ascDescSort
      ? allInfo.sort((a, b) =>
          a.auditDateUpdated.localeCompare(b.auditDateUpdated)
        )
      : allInfo.sort((a, b) =>
          b.auditDateUpdated.localeCompare(a.auditDateUpdated)
        );
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="audit header">
        <TableHead>
          <TableRow className="historyTableHead">
            <TableCell component="th" scope="row" />
            <TableCell component="th" scope="row">
              RX ID
            </TableCell>
            <TableCell component="th" scope="row">
              Date Dispensed
            </TableCell>
            <TableCell component="th" scope="row">
              Amount
            </TableCell>
            <TableCell component="th" scope="row">
              Auditor
            </TableCell>
            <TableCell component="th" scope="row">
              Outcome
            </TableCell>
            <TableCell component="th" scope="row">
              Audit Type
            </TableCell>
            <TableCell component="th" scope="row">
              Status
            </TableCell>
            <TableCell
              className="sort"
              onClick={handleAuditDateSort}
              component="th"
              scope="row"
            >
              Audit Date
              {showSortIcon ? (
                <Icon>
                  {ascDescSort ? (
                    <ArrowDropDownSharpIcon />
                  ) : (
                    <ArrowDropUpSharpIcon />
                  )}
                </Icon>
              ) : (
                ""
              )}
            </TableCell>
            <TableCell component="th" scope="row">
              Num Audits
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (
            <TableRow>
              <TableCell colSpan={10} style={{ textAlign: "center" }}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
          {allInfo.length >= 1 &&
            allInfo.map((c, index) =>
              c && c.pk && c.pk.rx_number ? (
                <Row key={index} row={c} index={index} userRoles={userRoles} />
              ) : null
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function Row(props) {
  const { row, index, userRoles } = props;
  const [open, setOpen] = React.useState(false);
  const [checkOut, setCheckOut] = React.useState();
  const { checkInRecord, checkOutRecord } = NonmatchedService();
  const { reaudit } = AuditService();
  const statusDict = {
    1: "New",
    2: "In Process",
    3: "Completed",
    4: "Submitted",
    5: "Cancelled",
    6: "Pending CE Review",
    7: "Pending",
    8: "Compliant",
    9: "Non-Compliant",
    10: "Aged-Out",
    11: "Ignore",
    12: "Re-Audit",
  };

  React.useEffect(() => {
    if (open) {
      checkOutRecord(
        row.rx_prx_id,
        (data) => {
          setCheckOut(true);
        },
        (err) => {
          console.log(err);
        }
      );
    }
    if (!open && checkOut) {
      checkInRecord(
        row.rx_prx_id,
        (data) => {
          setCheckOut(false);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }, [open]);

  const handleReAudit = () => {
    reaudit(
      row.rx_prx_id,
      (data) => {},
      (err) => {
        console.log(err);
      }
    );
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <TableRow
        className={index % 2 === 0 ? "EvenRow" : ""}
        sx={{ "& > *": { borderBottom: "unset" } }}
      >
        <TableCell>
          {/* <IconButton aria-label="expand row" size="small" onClick={handleOpen}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton> */}
        </TableCell>
        <TableCell>{row.pk.rx_number}</TableCell>
        <TableCell>
          {new Date(row.dateDispensed).toLocaleDateString()}
        </TableCell>
        <TableCell>{row.potentialSavings}</TableCell>
        <TableCell>{row.auditor}</TableCell>
        <TableCell>{row.outcome}</TableCell>
        <TableCell>{row.auditType}</TableCell>
        <TableCell>{statusDict[row.status]}</TableCell>
        <TableCell>{new Date(row.auditDateUpdated).toDateString()}</TableCell>
        <TableCell>{row.numAudits}</TableCell>
        <TableCell>
          {userRoles.includes("Assign ReAudit") ? (
            <Button onClick={handleReAudit}>ReAudit</Button>
          ) : (
            ""
          )}
        </TableCell>
        {/* <TableCell>{row.auditTimeMinutes}</TableCell> */}
      </TableRow>
      {/* <TableRow className={index % 2 === 0 ? "EvenRow" : ""}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <AuditHistoryDetailCard row={row}  collapse={handleOpen} />
          </Collapse>
        </TableCell>
      </TableRow> */}
    </React.Fragment>
  );
}
