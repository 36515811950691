import * as React from "react";
import FormControl from "@mui/material/FormControl";
import { Box, TextField } from "@mui/material";

export default function ScriptFilter({
  setScripts,
  filterOptions,
  setFilterOptions,
}) {
  const handleChange = (event) => {
    setScripts(() => [event.target.value]);
    setFilterOptions((prev) => ({ ...prev, rx_id: event.target.value }));
  };

  return (
    <Box className="filterBar">
      <FormControl sx={{ m: 1, minWidth: 165 }}>
        <TextField
          style={{ width: 250, background: "whitesmoke" }}
          label="Rx ID"
          placeholder="Rx ID"
          id="rx-id-text"
          variant="outlined"
          value={filterOptions.rx_id}
          sx={{ m: 1, width: "90%" }}
          onChange={handleChange}
        />
      </FormControl>
    </Box>
  );
}
